import { DateTime } from 'luxon';
import i18n from '@/i18n';
import validators from '../validators/validators';

const PASSWORD_MIN_LENGTH = 8;
const PROMOTIONAL_VIDEO_TITLE_LENGTH = 512;
const GENERAL_ASSEMBLY_VIMEO_URL_LENGTH = 512;
const GENERAL_ASSEMBLY_BACKGROUND_LENGTH = 512;
const MAX_VIDEO_SIZE = 524288000;
const MAX_IMAGE_SIZE = 2097152;
const MAX_FILE_SIZE = 20971520;

export default {
  REQUIRED: {
    default: validators.required,
    CONDITIONS: [validators.required(i18n.t('auth.errors.required.conditions').toString())],
    FIRSTNAME: [validators.required(i18n.t('auth.errors.required.firstname').toString())],
    LASTNAME: [validators.required(i18n.t('auth.errors.required.name').toString())],
    NAME: [validators.required(i18n.t('auth.errors.required.name').toString())],
    DATE: [validators.required(i18n.t('globals.form.errors.date.required').toString())],
    DESCRIPTION: [validators.required(i18n.t('auth.errors.required.description').toString())],
    TITLE: [validators.required(i18n.t('auth.errors.required.title').toString())],
  },
  LENGTH: {
    MAX: (max, field = '') => validators.length.max(max, { field }),
    MIN: (min, field = '') => validators.length.min(min, { field }),
  },
  VIDEO: {
    TYPE: {
      REQUIRED: validators.required(i18n.t('globals.form.errors.video.type.required').toString()),
      IS_VIMEO: validators.vimeo.format(i18n.t('globals.form.errors.video.type.vimeo').toString()),
      IS_VIMEO_OR_YOUTUBE: validators.vimeoOrYoutube.format(
        i18n.t('globals.form.errors.video.type.format').toString(),
      ),
    },
    UPLOAD: {
      IS_VIDEO: validators.file.is('video')(i18n.t('admin.video.errors.type').toString()),
      SIZE: validators.file.size(MAX_VIDEO_SIZE)(i18n.t('admin.video.errors.size').toString()),
    },
  },
  DATE: {
    GREATER_THAN: (date: DateTime, fieldName: string) =>
      validators.date.greaterThan(date, { field: fieldName }),
  },
  PHONE: {
    FORMAT: validators.phone.format(i18n.t('globals.form.errors.phone.format').toString()),
  },
  EMAIL: {
    REQUIRED: validators.required(i18n.t('auth.errors.email.required').toString()),
    FORMAT: validators.email.format(i18n.t('auth.errors.email.format').toString()),
  },
  URL: {
    FORMAT: validators.url.format(i18n.t('globals.form.errors.url.format').toString()),
  },
  PASSWORD: [
    validators.required(i18n.t('auth.errors.password.required').toString()),
    validators.length.min(PASSWORD_MIN_LENGTH, {
      msg: i18n.t('auth.errors.password.length', { count: PASSWORD_MIN_LENGTH }).toString(),
    }),
  ],
  AVATAR: [validators.file.size(MAX_IMAGE_SIZE)(i18n.t('auth.errors.avatar.size').toString())],
  TICKETING: {
    CARD: [validators.required(i18n.t('ticketing.errors.required.card').toString())],
    NAME: [validators.required(i18n.t('ticketing.errors.required.name').toString())],
    ADDRESS: [validators.required(i18n.t('ticketing.errors.required.address').toString())],
  },
  PARTNERS: {
    NAME: [validators.required(i18n.t('admin.partners.errors.required.name').toString())],
    TYPE: [validators.required(i18n.t('admin.partners.errors.required.type').toString())],
    PRIORITY: [validators.required(i18n.t('admin.partners.errors.required.priority').toString())],
    LOGOS: [validators.required(i18n.t('admin.partners.errors.required.logos').toString())],
  },
  TICKETS: {
    NAME: [validators.required(i18n.t('admin.tickets.errors.required.name.ticket').toString())],
    NAME_FR: [validators.required(i18n.t('admin.tickets.errors.required.name.fr').toString())],
    NAME_EN: [validators.required(i18n.t('admin.tickets.errors.required.name.en').toString())],
    VALUE_FR: [validators.required(i18n.t('admin.tickets.errors.required.value.fr').toString())],
    VALUE_EN: [validators.required(i18n.t('admin.tickets.errors.required.value.en').toString())],
    TYPE: [validators.required(i18n.t('admin.tickets.errors.required.type').toString())],
    ROLE: [validators.required(i18n.t('admin.tickets.errors.required.role').toString())],
    DESCRIPTION: [
      validators.required(i18n.t('admin.tickets.errors.required.description').toString()),
    ],
    PRICE: [
      validators.isNotEmpty(i18n.t('admin.tickets.errors.required.price').toString()),
      validators.number.greaterThanOrEqual(0)(
        i18n.t('admin.tickets.errors.required.price').toString(),
      ),
    ],
    CURRENCY: [validators.required(i18n.t('admin.tickets.errors.required.currency').toString())],
  },
  EVENTS: {
    NAME: [validators.required(i18n.t('event.errors.required.name').toString())],
    PROMOTIONAL_VIDEO_TITLE: [
      validators.length.max(PROMOTIONAL_VIDEO_TITLE_LENGTH)(
        i18n.t('event.errors.promotionalVideoTitle.length', {
          count: PROMOTIONAL_VIDEO_TITLE_LENGTH,
        }),
      ),
    ],
  },
  FILE: [
    validators.file.is('application')(i18n.t('admin.document.errors.type').toString()),
    validators.file.size(MAX_FILE_SIZE)(i18n.t('admin.document.errors.size').toString()),
  ],
  PICTURE: [
    validators.file.is('image')(i18n.t('admin.document.errors.type').toString()),
    validators.file.size(MAX_IMAGE_SIZE)(i18n.t('admin.document.errors.size').toString()),
  ],
  GENERAL_ASSEMBLIES: {
    NAME: [validators.required(i18n.t('admin.generalAssemblies.form.name.required').toString())],
    VIMEO_URL: [
      (v) =>
        (v?.length || 0) <= GENERAL_ASSEMBLY_VIMEO_URL_LENGTH ||
        i18n.t('admin.generalAssemblies.form.vimeoUrl.length', {
          count: GENERAL_ASSEMBLY_VIMEO_URL_LENGTH,
        }),
    ],
    BACKGROUND: [
      validators.required(i18n.t('admin.generalAssemblies.form.background.required').toString()),
      (v) =>
        (v?.length || 0) <= GENERAL_ASSEMBLY_BACKGROUND_LENGTH ||
        i18n.t('admin.generalAssemblies.form.background.length', {
          count: GENERAL_ASSEMBLY_BACKGROUND_LENGTH,
        }),
    ],
    START_TIME: [
      validators.required(i18n.t('admin.generalAssemblies.form.startTime.required').toString()),
    ],
    END_TIME: [
      validators.required(i18n.t('admin.generalAssemblies.form.endTime.required').toString()),
    ],
  },
  GROUP_CHAT: {
    NAME: [validators.required(i18n.t('admin.groupChat.errors.required.name').toString())],
  },
};
