import PhoneNumber from 'awesome-phonenumber';
import { DateTime } from 'luxon';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import DateUtil from '@/helpers/date/date.helper';

type Option = { field?: string; msg?: string };

const validate = (defaultMsg: TranslateResult) => (predicate: (v: unknown) => boolean) => (
  msg?: string,
) => (v: unknown): true | string | TranslateResult => predicate(v) || msg || defaultMsg;

const required = validate(i18n.t('globals.form.errors.required'))((v) => {
  if (Number.isInteger(v)) return v !== null || v !== undefined;
  return !!v;
});

const maxLengthPredicate = (max: number) => (value: unknown) => {
  if (Array.isArray(value) || typeof value === 'string') return !value || value.length <= max;

  return false;
};

const minLengthPredicate = (min: number) => (value: unknown) => {
  if (Array.isArray(value) || typeof value === 'string') return !value || value.length >= min;

  return false;
};

const length = {
  max: (max: number, option?: Option) =>
    validate(i18n.t('globals.form.errors.length.max', { field: option?.field || '', count: max }))(
      maxLengthPredicate(max),
    )(option?.msg),

  min: (min: number, option?: Option) =>
    validate(i18n.t('globals.form.errors.length.min', { field: option?.field || '', count: min }))(
      minLengthPredicate(min),
    )(option?.msg),
};

const dateGreaterThanPredicate = (date: DateTime) => (v: unknown) => {
  if (typeof v !== 'string') return false;

  const newDate = DateTime.fromFormat(v, DateUtil.DATETIME_W_FORMAT);
  return !v || date < newDate;
};

const date = {
  greaterThan: (date: DateTime, { field, msg }: Option) =>
    validate(i18n.t('globals.form.errors.date.greaterThan', { field }))(
      dateGreaterThanPredicate(date),
    )(msg),
};

const formatByRegex = (regex: RegExp) =>
  validate(i18n.t('globals.form.errors.format'))(
    (v) => !v || (typeof v === 'string' && regex.test(v)),
  );

const phone = {
  format: validate(i18n.t('globals.form.errors.phone.format'))((v: unknown) => {
    if (!v) return true;
    if (typeof v !== 'string') return true;

    const pnoneStr = v[0] !== '+' ? `+${v}` : v;
    const phone = new PhoneNumber(pnoneStr);
    return phone.isPossible();
  }),
};

const email = {
  format: formatByRegex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
};

const url = {
  format: formatByRegex(/^(ftp|http|https):\/\/[^ "]+$/),
};

const vimeo = {
  format: formatByRegex(/^https:\/\/player\.vimeo\.com\/video\/\d+$/),
};

const vimeoOrYoutube = {
  format: formatByRegex(/^https:\/\/((youtu\.be)?|(player\.vimeo\.com\/video)?)\/.+$/),
};

const is = (type: string) => (msg?: string) =>
  validate(i18n.t('globals.form.errors.type', { type }))(
    (files) =>
      !files ||
      (Array.isArray(files) && files.every((file) => file.type.includes(type))) ||
      files['type'].includes(type),
  )(msg);

const size = (maxLength: number) => (msg?: string) =>
  validate(i18n.t('globals.form.errors.size', { max: maxLength }))(
    (files) =>
      !files ||
      (Array.isArray(files) && files.every((file) => file.size < maxLength)) ||
      files['size'] < maxLength,
  )(msg);

const file = {
  is,
  size,
};

const isNotEmpty = validate(i18n.t('globals.form.errors.cannotBeEmpty'))((v) => {
  if (Number.isInteger(+v)) return v !== null || v !== undefined;
  if (typeof v === 'string') return v !== '';
  if (Array.isArray(v)) return v.length > 0;
  return false;
});

const number = {
  greaterThan: (max: number) =>
    validate(i18n.t('globals.form.errors.greatherThan', { number: max }))(
      (v) => typeof v === 'number' && v > max,
    ),
  greaterThanOrEqual: (max: number) =>
    validate(i18n.t('globals.form.errors.greatherThan', { number: max }))(
      (v) => typeof +v === 'number' && +v >= max,
    ),
};

const checkbox = (values) => [values.length > 0 || ''];

const validators = {
  checkbox,
  date,
  email,
  file,
  isNotEmpty,
  length,
  number,
  phone,
  required,
  url,
  validate,
  vimeo,
  vimeoOrYoutube,
};

export default validators;
